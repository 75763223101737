import { initSentryGatsby } from 'sentry'

if (process.env.NODE_ENV === 'production' && process.env.GATSBY_SENTRY_DSN_LANDING) {
  initSentryGatsby({
    dsn: process.env.GATSBY_SENTRY_DSN_LANDING,
    tracesSampleRate: process.env.GATSBY_SENTRY_TRACE_RATE,
    // We can't pass the environment value during the compile time
    // environment: process.env.ENVIRONMENT_NAME,
    release: process.env.GATSBY_SENTRY_RELEASE,
  })
}
