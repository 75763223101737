import cn from 'classnames'
import { Size, Variant } from '../types'

export const getButtonClassNames = (variant: Variant, size: Size): string =>
  cn(
    'rounded-md border px-8 py-4 text-center font-uniform-rnd font-medium',
    {
      primary:
        'border-transparent bg-primary-navy-dark text-white hover:border-border-color hover:bg-white hover:text-primary-navy-dark transition',
      secondary:
        'bg-alpha-trg-navy-15a text-primary-navy-dark hover:bg-primary-navy-dark hover:text-white transition',
      outline:
        'border-border-color bg-transparent text-primary-navy-dark hover:bg-primary-navy-dark hover:text-white transition',
      reversed:
        'bg-white text-primary-navy-dark hover:bg-primary-navy-dark hover:text-white transition',
    }[variant],
    {
      default: 'text-[0.9375rem] leading-none',
      md: 'text-[1.25rem] leading-none',
    }[size],
  )
