import React from 'react'
import { PRIVACY } from 'lib'
import { Container } from './Container'
import { FooterLink } from './FooterLink'

export const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 z-40 w-full bg-primary-navy-dark py-10 font-uniform-rnd">
      <Container>
        <div className="flex items-center justify-between text-[0.6875rem] leading-[1.3] text-text-body max-sm:flex-col max-sm:gap-4 max-sm:text-lg">
          <p>
            ©{new Date().getFullYear()} All Rights Reserved. ReturnPro® is a registered trademark of
            The Recon Group, LLP.
          </p>
          <div className="flex gap-6">
            <FooterLink to={PRIVACY.PRIVACY_POLICY_URI}>Privacy Policy</FooterLink>
            <FooterLink to={PRIVACY.USER_AGREEMENT_URI}>User Agreement</FooterLink>
          </div>
        </div>
      </Container>
    </footer>
  )
}
