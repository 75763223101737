import { useCallback } from 'react'
import { setURLSearchParam } from './setURLSearchParam'

type SearchParam = string | null

type GetParam = () => SearchParam
type SetParam = (value: SearchParam) => void
type UseURLSearchParamState = { get: GetParam; set: SetParam }

export const useURLSearchParamState = (paramName: string): UseURLSearchParamState => {
  const get = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const value = searchParams.get(paramName)
    if (value === null) return null
    return decodeURIComponent(value)
  }, [paramName])

  const set = useCallback(
    (value: SearchParam) => {
      setURLSearchParam(paramName, value)
    },
    [paramName],
  )

  return { get, set }
}
