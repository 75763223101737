import React from 'react'
import { Link } from 'gatsby'
import LogoIcon from '../assets/icons/ReturnPro-logo-icon.svg'
import LockIcon from '../assets/icons/lock-icon.svg'
import { EXTERNAL_URLS, INTERNAL_ROUTES } from '../constants'
import { Container } from './Container'
import { ExternalButtonLink } from './ExternalButtonLink'
import { ExternalLink } from './ExternaLink'

export const Header = () => {
  return (
    <header className="absolute left-0 top-0 z-30 w-full py-5">
      <Container>
        <div className="flex items-center justify-between max-sm:justify-center">
          <Link to={INTERNAL_ROUTES.HOME} className="max-sm:hidden">
            <LogoIcon height="2.5rem" width="9.5rem" />
          </Link>
          <div className="flex gap-5 max-sm:w-full max-sm:justify-between">
            <ExternalLink
              className="flex items-center gap-[10px] text-nav-item-text"
              href={EXTERNAL_URLS.SELLER_PORTAL}
            >
              <LockIcon />
              Sign In
            </ExternalLink>
            <ExternalButtonLink href={EXTERNAL_URLS.SIGN_UP} variant="primary">
              Get started
            </ExternalButtonLink>
          </div>
        </div>
      </Container>
    </header>
  )
}
