import { isLocalStorageAvailable } from './lib/isLocalStorageAvailable'

interface LocalStorageManagerModel<T> {
  get: () => T | null
  set: (data: T) => void
  clear: () => void
}

interface StoreItemModel<T> {
  data: T
}

export class LocalStorageManager<T> implements LocalStorageManagerModel<T> {
  key: string

  constructor(key: string) {
    if (!isLocalStorageAvailable()) {
      const noop = () => null

      this.get = noop
      this.set = noop
      this.clear = noop
    }
    this.key = key
  }

  get = () => {
    const jsonData = localStorage.getItem(this.key)
    if (!jsonData) {
      return null
    }

    try {
      const { data } = JSON.parse(jsonData) as StoreItemModel<T>
      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }

    return null
  }

  set = (data: T) => {
    const storeItem: StoreItemModel<T> = { data }
    localStorage.setItem(this.key, JSON.stringify(storeItem))
  }

  clear = () => {
    localStorage.removeItem(this.key)
  }
}
