import { useEffect } from 'react'

export const useConnectHubspotScript = () => {
  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      (window?._env_?.ENVIRONMENT_NAME !== 'Production' &&
        window?._env_?.ENVIRONMENT_NAME !== 'production')
    )
      return

    const script = document.createElement('script')
    script.src = 'https://js.hs-scripts.com/45680332.js'
    script.async = true
    script.defer = true
    script.id = 'hs-script-loader'
    script.type = 'text/javascript'
    document.head.appendChild(script)
  }, [])
}
