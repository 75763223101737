import { LocalStorageManager } from '../model'

// NOTE: seller-portal
export const lastOnboardingStepLocalStorageManager = new LocalStorageManager<number>(
  'lastOnboardingStep',
)
export const lastOnboardingMarketplaceTypeLocalStorageManager = new LocalStorageManager<string>(
  'lastOnboardingMarketplaceType',
)
export const hideContinueOnboardingBannerLocalStorageManager = new LocalStorageManager<boolean>(
  'hideContinueOnboardingBanner',
)
export const lastOnboardingMarketplaceIdLocalStorageManager = new LocalStorageManager<string>(
  'lastOnboardingMarketplaceId',
)
export const isOnboardingFinishedLocalStorageManager = new LocalStorageManager<boolean>(
  'isOnboardingFinished',
)
export const shopifyInstallationCredsLocalStorageManager = new LocalStorageManager<{
  accessToken: string
  domain: string
  apiKey: string
  apiSecretKey: string
}>('shopifyInstallationCreds')
export const isOnboardingBannerVisibleLocalStorageManager = new LocalStorageManager<boolean>(
  'isOnboardingBannerVisible',
)
export const onboardingSkippedSteps = new LocalStorageManager<number[]>('onboardingSkippedSteps')

// NOTE: shopper-portal
export const accessTokenLocalStorageManager = new LocalStorageManager<string>('accessToken')
export const refreshTokenLocalStorageManager = new LocalStorageManager<string>('refreshToken')
export const currentUserLocalStorageManager = new LocalStorageManager<{
  id: string
  email: string
}>('currentUser')
export const currentOrderIdLocalStorageManager = new LocalStorageManager<string>('currentOrderId')
export const currentOrganizationIdLocalStorageManager = new LocalStorageManager<string>(
  'currentOrganizationId',
)
export const currentEmailLocalStorageManager = new LocalStorageManager<string>('currentEmail')
export const currentInternalOrderIdLocalStorageManager = new LocalStorageManager<number>(
  'internalOrderId',
)
export const currentClaimIdLocalStorageManager = new LocalStorageManager<string>('claimId')
export const tablesOrderedValueColumIdsStorageManager = new LocalStorageManager<{
  [tableId: string]: string[]
}>('tablesOrderedValueColumIds')
