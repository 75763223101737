export function isLocalStorageAvailable(): boolean {
  if (typeof localStorage === 'object') {
    try {
      localStorage.setItem('localStorage', '1')
      localStorage.removeItem('localStorage')
      return true
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  }
  return false
}
