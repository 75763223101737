/* eslint-disable no-param-reassign */

import * as Sentry from '@sentry/gatsby'
import {
  SENTRY_DEFAULT_ENVIRONMENT,
  SENTRY_DEFAULT_RELEASE_NAME,
  SENTRY_DEFAULT_TRACE_RATE,
  SentryConfig,
} from './lib'

export function initSentryGatsby({
  dsn,
  tracesSampleRate = SENTRY_DEFAULT_TRACE_RATE,
  release = SENTRY_DEFAULT_RELEASE_NAME,
}: SentryConfig) {
  if (!dsn) return

  Sentry.init({
    dsn,
    release,
    tracesSampleRate: Number(tracesSampleRate),
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
