export const platformFeaturesTexts = {
  marketplaceIntegration: {
    featureName: 'Marketplace integration and shopper experience',
    benefits: [
      'Effortlessly connect marketplace accounts such as Walmart and Shopify (others coming soon!)',
      'Customize text and visual brand elements for a seamless returns experience',
      'Implement custom domain redirect',
      'Define return reasons',
      'Specify return windows and eligibility',
    ],
  },
  flexibleReturns: {
    featureName: 'Flexible returns, instant refunds, exchanges and keep it options',
    benefits: [
      'Configure refund methods, incentivize repurchases, and offer store credit',
      'Streamline product exchanges and keep it rules for items that are not economical to ship back',
      'Enable smooth product exchanges and similar item recommendations',
    ],
  },
  convenientDropOff: {
    featureName: 'Convenient drop off and ship back',
    benefits: [
      'Access a network of 10,000+ partner drop off locations',
      'Simplify returns with QR code, and label-free options',
      'Benefit from ReturnPro’s discounted shipping rates',
      'Seamlessly integrate your preferred carrier services and connect to any carrier network (FedEx, UPS, CanadaPost, and more coming soon)',
    ],
  },
  reverseSupplyChain: {
    featureName: 'Reverse Supply Chain',
    benefits: [
      'Value-add services such as consolidation, sorting, testing, data wiping, grading, and refurbishing bring items back to life',
      'Intelligent disposition engine optimizes touches to maximize profitability',
      'Leverage geolocation or customized routing rules for returns',
    ],
  },
  reCommerce: {
    featureName: 'ReCommerce',
    benefits: [
      'Simultaneously list your returns across multiple marketplaces',
      'Automatically relist or restock items on their original sales channel',
      'Utilize item templates for individual product grades',
    ],
  },
  notifications: {
    featureName: 'Notifications, support, and reporting',
    benefits: [
      'Access email and in-app support for assistance',
      'Access a KPI dashboard and out-of-the-box reporting',
    ],
  },
}
