import { z } from 'zod'
import { BackendErrorCodesEnum } from './BackendErrorCodesEnum'

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type RequestParams = object

export interface BaseResponse {
  traceId?: string
}

type RedirectData = {
  redirectUrl?: string
}

export type ResponseData<T> = T & RedirectData

export interface SuccessResponse<T> extends BaseResponse {
  data: ResponseData<T>
}

export const FailResponseSchema = z.object({
  traceId: z.string().optional(),
  status: z.number(),
  detail: z.string(),
  errorCode: z.union([z.nativeEnum(BackendErrorCodesEnum), z.string(), z.null()]).optional(),
  title: z.string().optional(),
  type: z.string().optional(),
})

export type FailResponse = z.infer<typeof FailResponseSchema>

export type Response<T> = SuccessResponse<T> | FailResponse
