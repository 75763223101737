import { z } from 'zod'
import { stringTrimTransformFunction } from '../lib'
import { VALIDATION_MESSAGES } from '../../constants'

/* 
  ^\s* matches any number of whitespace characters at the start of the string.
  [^\s@]+@[^\s@]+\.[^\s@]+ matches an email address, which consists of one or more characters that are not a whitespace or an @ symbol,
   followed by an @ symbol, followed by one or more characters that are not a whitespace or an @ symbol, followed by a . symbol, 
   followed by one or more characters that are not a whitespace or an @ symbol.
  \s*$ matches any number of whitespace characters at the end of the string.

  Examples of valid email addresses:
*/
export const EMAIL_REGEX = /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/

export const EmailFieldSchema = z
  .string()
  .superRefine((value, ctx) => {
    if (!value) {
      // NOTE: use makeStringZodFieldOptional decorator to make your email field as optional
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: VALIDATION_MESSAGES.REQUIRED_FIELD,
      })
      return
    }
    if (!EMAIL_REGEX.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: VALIDATION_MESSAGES.INVALID_EMAIL_ADDRESS,
      })
    }
  })
  .transform(stringTrimTransformFunction)
