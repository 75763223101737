import { Link } from 'gatsby'
import React, { PropsWithChildren } from 'react'
import cn from 'classnames'

type Props = PropsWithChildren & {
  to: string
  className?: string
}

export const FooterLink = ({ to, className, children }: Props) => {
  return (
    <Link
      to={to}
      className={cn(
        'text-center font-uniform-rnd text-[0.6875rem] leading-[1.3] max-sm:text-xl',
        className,
      )}
    >
      {children}
    </Link>
  )
}
