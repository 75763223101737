import { RefinementCtx, z } from 'zod'
import { stringTrimTransformFunction } from '../lib'
import { VALIDATION_MESSAGES } from '../../constants'

export const PasswordFieldSchema = z
  .string()
  .min(1, VALIDATION_MESSAGES.REQUIRED_FIELD)
  .min(8, ' ')
  .transform(stringTrimTransformFunction)

export const PasswordConfirmFieldSchema = z.string().transform(stringTrimTransformFunction)

export const passwordMatchRefinement = (
  value: { password: string; confirmPassword: string },
  ctx: RefinementCtx,
) => {
  if (value.confirmPassword !== value.password) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: VALIDATION_MESSAGES.PASSWORDS_DO_NOT_MATCH,
      path: ['confirmPassword'],
    })
  }

  if (value.password.length !== 0 && value.confirmPassword.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: VALIDATION_MESSAGES.CONFIRM_PASSWORD,
      path: ['confirmPassword'],
    })
  }
}
