import React from 'react'
import cn from 'classnames'
import { ExternalLink } from '../ExternaLink'
import { getButtonClassNames } from '../../helpers'
import { ExternalButtonLinkProps } from './types'

export const ExternalButtonLink = ({
  className,
  size = 'default',
  variant = 'primary',
  ...nativeProps
}: ExternalButtonLinkProps) => {
  return (
    <ExternalLink
      {...nativeProps}
      className={cn('inline-block', getButtonClassNames(variant, size), className)}
    />
  )
}
